@include is_project('vob') {
  @font-face {
    font-family: "TheMix C5";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url("/asstets/novio/fonts/TheMixC5-3_Light.woff2") format("woff2");
  }

  @font-face {
    font-family: "TheMix C5";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("/assets/novio/fonts/TheMixC5-4_SemiLight.woff2") format("woff2");
  }

  @font-face {
    font-family: "TheMix C5";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url("/assets/novio/fonts/novio/fonts/TheMixC5-5_Plain.woff2") format("woff2");
  }

  @font-face {
    font-family: "TheMix C5";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url("/assets/novio/fonts/TheMixC5-6_SemiBold.woff2") format("woff2");
  }

  @font-face {
    font-family: "TheMix C5";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url("/assets/novio/fonts/TheMixC5-7_Bold.woff2") format("woff2");
  }

  @font-face {
    font-family: "TheMix C5";
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url("/assets/novio/fonts/TheMixC5-8_ExtraBold.woff2") format("woff2");
  }

  @font-face {
    font-family: "TheMix C5";
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url("/assets/novio/fonts/TheMixC5-9_Black.woff2") format("woff2");
  }
}
